import React from 'react';
import {
  ComponentPreview,
  DontDo,
  DontDoItem,
  Link,
  PageHero,
  PageNavigation,
  PageNavigationLink,
  PageWithSubNav,
  Paragraph,
  Section,
  SectionSubhead,
} from 'docComponents';
import { Headline, Subhead, Text, ItemTitle } from 'hudl-uniform-ui-components';
import headlinePreviewData from '../../../data/previews/headline.yml';
import subheadPreviewData from '../../../data/previews/subhead.yml';
import itemtitlePreviewData from '../../../data/previews/itemtitle.yml';
import textPreviewData from '../../../data/previews/text.yml';
import pageHeroData from '../../../data/pages/guidelines.yml';

const IndexPage = () => {
  return (
    <PageWithSubNav
      pageType="design"
      title="Typography"
      designCode
      subnav="guidelines">
      <PageHero heroData={pageHeroData} tierTwo="Typography" />

      <PageNavigation>
        <PageNavigationLink>Typefaces</PageNavigationLink>
        <PageNavigationLink>Usage</PageNavigationLink>
        <PageNavigationLink>Units</PageNavigationLink>
      </PageNavigation>

      <Section title="Typefaces">
        <Paragraph>
          We strive for our products to be reliable and predictable. For this
          reason, we use system typefaces for most of our content. System
          typefaces come pre-installed on every device. They are battle tested,
          free to use and have little to no technical overhead.
        </Paragraph>

        <SectionSubhead>Helvetica</SectionSubhead>
        <Paragraph>
          When designing for the web, we use Helvetica. We also have font stack
          fallbacks of Arial and sans-serif.
        </Paragraph>

        <SectionSubhead>Roboto</SectionSubhead>
        <Paragraph>
          When designing for Android, we design with{' '}
          <Link href="https://material.io/guidelines/resources/roboto-noto-fonts.html">
            Roboto
          </Link>
          .
        </Paragraph>

        <SectionSubhead>San Francisco</SectionSubhead>
        <Paragraph>
          When designing for Apple, we design with{' '}
          <Link href="https://developer.apple.com/fonts/">San Francisco</Link>.
          Follow Apple’s{' '}
          <Link href="https://developer.apple.com/ios/human-interface-guidelines/visual-design/typography/">
            Human Interface Guidelines
          </Link>{' '}
          for guidance on dynamic type sizes and font variants.
        </Paragraph>

        <SectionSubhead>Segoe</SectionSubhead>
        <Paragraph>
          When designing for Windows products, we design with{' '}
          <Link href="https://docs.microsoft.com/en-us/windows/uwp/design/downloads/index">
            Segoe UI
          </Link>
          .
        </Paragraph>
      </Section>

      <Section title="Usage">
        <Paragraph>
          When it comes to typography in our products, form follows function. We
          aim for optimal readability and clear hierarchy on a variety of
          devices. This is handled for you with our Type{' '}
          <Link href="/components/type" isDesignCodeLink>
            components
          </Link>
          .
        </Paragraph>

        <SectionSubhead>Headline</SectionSubhead>
        <Paragraph>
          <Link href="/components/type/headline" isDesignCodeLink>
            Headlines
          </Link>{' '}
          establish the first-level typographical hierarchy for sections
          throughout the interface.
        </Paragraph>

        <ComponentPreview
          name="HeadlineSize"
          layout="default"
          previewData={headlinePreviewData.levels}>
          <Headline>We help coaches and athletes win with video.</Headline>
        </ComponentPreview>

        <SectionSubhead>Subhead</SectionSubhead>
        <Paragraph>
          <Link href="/components/type/subhead" isDesignCodeLink>
            Subheads
          </Link>{' '}
          create ownership and define a specific area of an interface, such as a
          list or small text block.
        </Paragraph>
        <ComponentPreview
          name="SubheadSize"
          layout="default"
          previewData={subheadPreviewData.levels}>
          <Subhead>We help you win.</Subhead>
        </ComponentPreview>

        <SectionSubhead>Text</SectionSubhead>
        <Paragraph>
          <Link href="/components/type/text" isDesignCodeLink>
            Text
          </Link>{' '}
          is the most common typographical element within our interfaces. Think
          of it as the body copy.
        </Paragraph>
        <ComponentPreview
          name="TextSize"
          layout="default"
          previewData={textPreviewData.levels}>
          <Text>
            If sending a check, please include the invoice number in the memo.
          </Text>
        </ComponentPreview>

        <SectionSubhead>Item Title</SectionSubhead>
        <Paragraph>
          Think of{' '}
          <Link href="/components/type/item-title" isDesignCodeLink>
            item titles
          </Link>{' '}
          as a subhead for a single item. They provide extra weight when
          necessary, but aren’t essential for everyday use.
        </Paragraph>
        <ComponentPreview
          name="ItemTitleSize"
          layout="default"
          previewData={itemtitlePreviewData.levels}>
          <ItemTitle>Email Address</ItemTitle>
        </ComponentPreview>

        <DontDo gridLayout="2">
          <DontDoItem
            type="dont"
            text="mix typefaces with platforms."
            img="typography-mix-typefaces-dont"
          />
          <DontDoItem
            type="do"
            text="use the correct typeface for the platform listed."
            img="typography-mix-typefaces-do"
          />
        </DontDo>

        <SectionSubhead>Microcopy Guidelines</SectionSubhead>
        <Paragraph>
          When writing <Link href="/words/">words</Link>, follow Hudl’s voice
          and tone and copy guidelines.
        </Paragraph>
        <Paragraph>
          {/* eslint-disable max-len */}
          Follow our{' '}
          <Link href="/words/content-elements/labels-non-label-text#Non-Label%20UI%20Text">
            non-label guidelines
          </Link>{' '}
          when typesetting headlines, subheads and text. Follow our{' '}
          <Link href="/words/content-elements/labels-non-label-text#UI%20Labels">
            UI label guidelines
          </Link>{' '}
          when typesetting item titles.
          {/* eslint-enable max-len */}
        </Paragraph>
      </Section>

      <Section title="Units">
        <Paragraph>
          For the sake of simplicity, we use pixels (px) as our standard unit of
          measurement for fixed values in this documentation. Pixel values will
          need to be converted for each platform. We chose pixels as our the
          common denominator because it’s the default unit in screen graphics
          software (Sketch, Photoshop, Illustrator, etc.).
        </Paragraph>

        <SectionSubhead>Web</SectionSubhead>
        <Paragraph>
          On the web we’re using root ems (rem) with a 16px base. This means
          1rem = 16px, or 1px = 0.0625rem.
        </Paragraph>

        <SectionSubhead>Apple</SectionSubhead>
        <Paragraph>
          For Apple, type and every other dimension uses points (pt). This means
          that in the @1x display resolution 1px = 1 pt. For different display
          resolutions the px value will need to be adjusted accordingly (e.g.,
          1pt @2x = 0.5px and 1pt @3x = 0.33px).
        </Paragraph>

        <SectionSubhead>Android</SectionSubhead>
        <Paragraph>
          For Android, type and every other dimension uses density-independent
          pixels (dp). This means that in the medium density (mdpi) display
          density 1px = 1dp. For different display densities the px value will
          need to be upscaled accordingly (e.g., 1dp @hdpi = 1.5px, 1dp @xhdpi =
          2px, 1dp @xxhdpi = 3px, and 1px @xxxhdpi = 4px).
        </Paragraph>
      </Section>
    </PageWithSubNav>
  );
};

export default IndexPage;
